import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { toast } from 'react-toastify';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import axios from 'axios';
import ProductStatus from '../../pages/ProductStatus';
import { Category } from '@mui/icons-material';


const ProductStatusSKUTable: React.FC = () => {
    const client = useAppSelector(state => state.client);
    const { current_brand } = client

    const [productStatus, setProductStatus] = useState([])
    const [cityProductStatus, setCityProductStatus] = useState([])
    const [pincodeProductStatus, setPincodeProductStatus] = useState([])

    const [rowSelect, setRowSelect] = useState(null)
    const [expandedRows, setExpandedRows] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRow, setTotalRow] = useState<any[]>([]);
    const [kpiSelectToggle, setKpiSelectToggle] = useState<boolean>(false);
    const [kpiSelectToggle1, setKpiSelectToggle1] = useState<boolean>(false);

    const popupRef = useRef<HTMLDivElement>(null);


    const [selectedRow, setselectedRow] = useState<any>(undefined);
    const [selectedProductName, setSelectedProductName] = useState('');

    const [selectedCity, setSelectedCity] = useState<any>(undefined);
    const [selectedCityName, setSelectedCityName] = useState<any>('');



    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/qcomm/product-status?client_name=${current_brand.client_name}`)
            .then((res: any) => {
                res.data.forEach((data: any) => {

                    data.mrp = `${data.min_mrp}-${data.max_mrp}`
                    data.sp = `${data.min_sp}-${data.max_sp}`

                    if (data.zepto_cities) {
                        data.zepto = `${data.zepto_cities} (${data.zepto_pincodes})`
                    }

                    if (data.blinkit_cities) {
                        data.blinkit = `${data.blinkit_cities} (${data.blinkit_pincodes})`
                    }

                    if (data['swiggy instamart_cities']) {
                        data.instamart = `${data['swiggy instamart_cities']} (${data['swiggy instamart_pincodes']})`
                    }
                });
                setProductStatus(res.data)
            }).catch((error: any) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
    }, [])




    useEffect(() => {
        if (selectedRow) {
            setIsLoading(true);
            console.log(selectedRow.harmonized_id);

            let api = `${BASE_URL_NEW}/qcomm/product-status?client_name=${current_brand.client_name}&category=${selectedRow.category}&platform_code=${selectedRow.platform_code}`
            if(selectedRow.harmonized_id) api += `&harmonized_id=${selectedRow.harmonized_id}`
            
            axios.get(api)
                .then((res: any) => {
                    res.data.forEach((data: any) => {
                        data.mrp = `${data.min_mrp}-${data.max_mrp}`
                        data.sp = `${data.min_sp}-${data.max_sp}`
                    });
                    setCityProductStatus(res.data)
                }).catch((error: any) => {
                    console.log(error);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    }, [selectedRow])




    useEffect(() => {
        if (selectedCity) {
            setIsLoading(true);

            let api = `${BASE_URL_NEW}/qcomm/product-status?client_name=${current_brand.client_name}&category=${selectedRow.category}&platform_code=${selectedRow.platform_code}&city=${selectedCity.city}`
            if(selectedRow.harmonized_id) api += `&harmonized_id=${selectedRow.harmonized_id}`


            axios.get(api)
                .then((res: any) => {
                    res.data.forEach((data: any) => {
                        data.mrp = `${data.min_mrp}-${data.max_mrp}`
                        data.sp = `${data.min_sp}-${data.max_sp}`
                    });
                    setPincodeProductStatus(res.data)
                }).catch((error: any) => {
                    console.log(error);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    }, [selectedCity])


    const [columns, setColumns] = useState<any[]>([
        { header: 'Id', field: 'identifier', width: '120px', checked: true },
        { header: 'Product Name', field: 'short_name', width: '180px', checked: true },
        { header: 'Category', field: 'category', width: '90px', checked: true },
        { header: 'Pack Size', field: 'pack_size', width: '90px', checked: true },
        { header: 'MRP', field: 'mrp', width: '70px', checked: true },
        { header: 'Blinkit', field: 'blinkit', width: '160px', checked: true },
        { header: 'Zepto', field: 'zepto', width: '160px', checked: true },
        { header: 'Instamart', field: 'instamart', width: '160px', checked: true },
    ]);

    const [columnsCity, setColumnsCity] = useState<any[]>([
        { header: 'City', field: 'city', width: '120px', checked: true },
        { header: 'Product Name', field: 'short_name', width: '220px', checked: true },
        { header: 'Active', field: 'blinkit_active_locations', width: '120px', checked: true },
        { header: 'Inactive', field: 'blinkit_inactive_locations', width: '120px', checked: true },
        { header: 'Active', field: 'zepto_active_locations', width: '120px', checked: true },
        { header: 'Inactive', field: 'zepto_inactive_locations', width: '120px', checked: true },
        { header: 'Active', field: 'swiggy instamart_active_locations', width: '120px', checked: true },
        { header: 'Inactive', field: 'swiggy instamart_inactive_locations', width: '120px', checked: true },
    ]);

    const [columnsPincode, setColumnsPincode] = useState<any[]>([
        { header: 'Pincode', field: 'pincode', width: '120px', checked: true },
        { header: 'Product Name', field: 'short_name', width: '220px', checked: true },
        { header: 'Active', field: 'blinkit_active_locations', width: '120px', checked: true },
        { header: 'Inactive', field: 'blinkit_inactive_locations', width: '120px', checked: true },
        { header: 'Active', field: 'zepto_active_locations', width: '120px', checked: true },
        { header: 'Inactive', field: 'zepto_inactive_locations', width: '120px', checked: true },
        { header: 'Active', field: 'swiggy instamart_active_locations', width: '120px', checked: true },
        { header: 'Inactive', field: 'swiggy instamart_inactive_locations', width: '120px', checked: true },
    ]);

    const handleRowSelect = (e: any) => {

        if (e.value) {
            if (!selectedRow) {
                setselectedRow({ harmonized_id: e.value.harmonized_id, category: e.value.category, platform_code: e.value['zepto_platform_code'] || e.value['blinkit_platform_code'] || e.value['swiggy instamart_platform_code'] });
                setSelectedProductName(e.value?.short_name);
            }
            else if (selectedRow && !selectedCity) {
                setSelectedCity({ harmonized_id: e.value.harmonized_id, category: e.value.category, city: e.value.city, platform_code: e.value['zepto_platform_code'] || e.value['blinkit_platform_code'] || e.value['swiggy instamart_platform_code'] });
                setSelectedCityName(e.value.city)
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setKpiSelectToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleColumnSelect = () => {
        setKpiSelectToggle(prev => !prev);
    }

    const handleColumnSelect1 = () => {
        setKpiSelectToggle1(prev => !prev);
    }

    const getBackgroundColorHeader = (header: string) => {
        const colorMap: Record<string, string> = {
            Blinkit: '#F8F0C9',
            Instamart: '#F7D8BF',
            Zepto: '#DDBBF0',
        };
        return colorMap[header] || 'rgba(245, 245, 247, 1)'; // Default color
    };

    const headerGroup = (
        selectedRow ?
            <ColumnGroup>
                <Row>
                    <Column header="" colSpan={2} style={{ width: '300px' }} />
                    <Column header="Blinkit" colSpan={2} headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader('Blinkit'), }} />
                    <Column header="Zepto" colSpan={2} headerStyle={{ color: '#000000', textAlign: 'center', justifyContent: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader('Zepto'), }} />
                    <Column header="Instamart" colSpan={2} headerStyle={{ color: '#000000', textAlign: 'center', justifyContent: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader('Instamart'), }} />
                </Row>
                <Row>
                    {(selectedCity ? columnsPincode : columnsCity).map((col) => (
                        <Column header={col.header}
                            headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                            style={{ width: col.width }}
                        />
                    ))}
                </Row>
            </ColumnGroup>
            :
            <ColumnGroup>
                <Row>
                    {columns.map((col) => (
                        <Column header={col.header}
                            headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                            style={{ width: col.width }}
                        />
                    ))}
                </Row>
            </ColumnGroup>

    );


    return (
        <div style={{ marginTop: '1rem' }} className=' table_card_new'>
            <div style={{ position: 'relative' }}>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <button className='table_btn1' style={{ marginRight: '0.5rem' }} >
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                        </svg>
                    </button>
                    <span style={{ color: '#06486A' }}>Product Status</span>
                    <div style={{ margin: '0rem 0.5rem', color: '#06486A' }}>/</div>

                    {!selectedRow ?
                        <span className='table_kpi_name'> SKU</span> :
                        <>
                            <span style={{ color: '#06486A' }}><span style={{ cursor: 'pointer' }} onClick={() => { setselectedRow(undefined); }}>{selectedProductName}</span> <i className="bi bi-filter" style={{ color: '#C1C1C1', cursor: 'pointer' }} onClick={handleColumnSelect}>
                                {kpiSelectToggle && (
                                    <div
                                        className="column_select-box"
                                        style={{
                                            position: 'absolute',
                                            marginTop: '0.5rem',
                                            backgroundColor: '#fff',
                                            fontStyle: 'normal',
                                            maxHeight:'16rem',
                                            overflowY:'auto',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '0.5rem',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                            zIndex: 999,
                                        }}
                                    >
                                        {productStatus.map((el: any) => (
                                            <div
                                                key={el.short_name}
                                                className="column_select-boxname"
                                                style={{ padding: '0.3rem 0.5rem', cursor: 'pointer', textTransform: 'capitalize', fontSize: '0.85rem', color: '#000', borderBottom: '1px solid #ccc', }}
                                                onClick={() => { setselectedRow({ harmonized_id: el.harmonized_id, category: el.category, platform_code: el['zepto_platform_code'] || el['blinkit_platform_code'] || el['swiggy instamart_platform_code'] }); setSelectedProductName(el.short_name); }} // Handle selection
                                            >
                                                {el.short_name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </i></span>
                            <div style={{ margin: '0rem 0.5rem', color: '#06486A' }}>/</div>
                            {!selectedCity ?
                                <span className='table_kpi_name'> City</span> :
                                <>
                                    <span style={{ color: '#06486A' }}><span style={{ cursor: 'pointer' }} onClick={() => { }}> {selectedCityName}</span>
                                        <i className="bi bi-filter" style={{ color: '#C1C1C1', cursor: 'pointer' }} onClick={handleColumnSelect1}>
                                            {kpiSelectToggle1 && (
                                                <div
                                                    className="column_select-box"
                                                    style={{
                                                        position: 'absolute',
                                                        marginTop: '0.5rem',
                                                        backgroundColor: '#fff',
                                                        fontStyle: 'normal',
                                                        border: '1px solid #ccc',
                                                        maxHeight:'16rem',
                                                        overflowY:'auto',
                                                        borderRadius: '4px',
                                                        padding: '0.5rem',
                                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                        zIndex: 999,
                                                    }}
                                                >
                                                    {cityProductStatus.map((el: any) => (
                                                        <div
                                                            key={el.city}
                                                            className="column_select-boxname"
                                                            style={{
                                                                padding: '0.3rem 0.5rem',
                                                                cursor: 'pointer',
                                                                textTransform: 'capitalize',
                                                                borderBottom: '1px solid #ccc',
                                                                fontSize: '0.85rem',
                                                                color: '#000',
                                                            }}
                                                            onClick={() => {
                                                                setSelectedCity({ harmonized_id: el.harmonized_id, category: el.category, city: el.city, platform_code: el['zepto_platform_code'] || el['blinkit_platform_code'] || el['swiggy instamart_platform_code'] });
                                                                setSelectedCityName(el.city)
                                                            }}
                                                        >
                                                            {el.city}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </i></span>
                                    <div style={{ margin: '0rem 0.5rem', color: '#06486A' }}>/</div>
                                    <span className='table_kpi_name'>Pincode</span>
                                </>
                            }
                        </>
                    }
                </div>

                <PrimeReactProvider>
                    {
                        isLoading ?
                            <LoaderSkeletonTable height='350px' /> :
                            <DataTable
                                value={selectedRow ? (selectedCity ? pincodeProductStatus : cityProductStatus) : productStatus}
                                size='small'
                                columnResizeMode="expand"
                                resizableColumns
                                selectionMode="single"
                                selection={rowSelect}
                                onSelectionChange={handleRowSelect}
                                headerColumnGroup={headerGroup}
                                showGridlines
                                id='unique-table'
                                scrollable scrollHeight="350px"
                            >
                                {(selectedRow ? (selectedCity ? columnsPincode : columnsCity) : columns).filter((column) => column.checked).map((col, index) => (
                                    <Column
                                        key={index}
                                        field={col.field}
                                        style={{ width: col.width }}
                                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                        body={col.body}
                                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                                    />
                                ))}
                            </DataTable>
                    }
                </PrimeReactProvider>

            </div>
        </div>
    )
}

export default ProductStatusSKUTable