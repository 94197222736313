import { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import './authStyle.scss';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../redux/hook";
import { login } from "../../../redux/slices/authSlice";
import axios from "axios";
import { BASE_URL_NEW } from "../../../appConstant";
import { userDetails } from "../../../redux/slices/userSlice";
import { brandDetails } from "../../../redux/slices/clientSlice";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    password: "",
    email: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    const { email, password } = state;
    if (email === "") {
      toast.error('email is required')
      return;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      toast.error('Please enter a valid email')
      return;
    } else if (password === "") {
      toast.error('Password is required')
      return;
    } else if (password.length < 5) {
      toast.error('Password must be longer than or equal to 5 characters')
      return;
    }
    e.preventDefault();
    setLoading(true);
    try {
      const auth = await axios.post(`${BASE_URL_NEW}/auth/login`, state)
      const { token, user } = auth.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(userDetails(user));
      dispatch(login());
      navigate('/home/brandOnboarding');
      setLoading(false);
      // const brands = await axios.get(`${BASE_URL_NEW}/user/${user?._id}/userBrands`)
      // let brandArr = brands.data;
      // // console.log(brandArr);
      // let current_brand = brandArr[0];
      // dispatch(brandDetails({ brand_array: brandArr, current_brand: current_brand, current_agency: current_brand.agency }));
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.error || 'something went wrong')
    }



    //   // try {
    //   //   const auth = await axios.post(`${BASE_URL}auth/login`, state);
    //   //   const { token, user } = auth.data.data;
    //   //   dispatch(saveUserData(user));
    //   //   // console.log(user);
    //   //   localStorage.setItem("token", token);
    //   //   if (user.user_domain === 'enlytical_admin') {
    //   //     const getAgency = await axios.get(`${BASE_URL}agency`, {
    //   //       headers: {
    //   //         token
    //   //       }
    //   //     })
    //   //     const { agency_array } = getAgency.data.data;
    //   //     const { _id, } = agency_array[0];
    //   //     // console.log(agency_array);
    //   //     const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
    //   //       headers: {
    //   //         token
    //   //       }
    //   //     })
    //   //     const { brand_array } = getBrand.data.data;
    //   //     // console.log(brand_array);
    //   //     let current_brand = brand_array[0];
    //   //       if (brand_array?.length) {
    //   //         let currentBrandInLocal = localStorage.getItem("currentBrand");
    //   //         if (currentBrandInLocal) {
    //   //           for (let b of brand_array) {
    //   //             if (b._id === currentBrandInLocal) {
    //   //               current_brand = b;
    //   //               break;
    //   //             }
    //   //           }
    //   //         }
    //   //       }
    //   //     dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
    //   //     if (brand_array.length === 0) {
    //   //       navigate("/inactiveUser");
    //   //     } else {
    //   //       navigate("/home/app");
    //   //     }
    //   //   } else if (user.user_domain === 'user') {
    //   //     const getAgency = await axios.get(`${BASE_URL}agency`, {
    //   //       headers: {
    //   //         token
    //   //       }
    //   //     })
    //   //     const { agency_array } = getAgency.data.data;
    //   //     // console.log(agency_array);
    //   //     if (agency_array.length === 0) {
    //   //       const getBrand = await axios.get(`${BASE_URL}brand`, {
    //   //         headers: {
    //   //           token
    //   //         }
    //   //       })
    //   //       const { brand_array } = getBrand.data.data;
    //   //       // console.log(brand_array);
    //   //       let current_brand = brand_array[0];
    //   //       if (brand_array?.length) {
    //   //         let currentBrandInLocal = localStorage.getItem("currentBrand");
    //   //         if (currentBrandInLocal) {
    //   //           for (let b of brand_array) {
    //   //             if (b._id === currentBrandInLocal) {
    //   //               current_brand = b;
    //   //               break;
    //   //             }
    //   //           }
    //   //         }
    //   //       }
    //   //       dispatch(saveAppParamsData({ agency_array, current_agency: [], brand_array, current_brand: current_brand }));

    //   //       if (brand_array.length === 0) {
    //   //         navigate("/inactiveUser");
    //   //       } else {
    //   //         navigate("/home/app");
    //   //       }
    //   //     } else if (agency_array.length > 0) {
    //   //       const { _id, name } = agency_array[0];
    //   //       const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
    //   //         headers: {
    //   //           token
    //   //         }
    //   //       })
    //   //       const { brand_array } = getBrand.data.data;
    //   //       let current_brand = brand_array[0];
    //   //       if (brand_array?.length) {
    //   //         let currentBrandInLocal = localStorage.getItem("currentBrand");
    //   //         if (currentBrandInLocal) {
    //   //           for (let b of brand_array) {
    //   //             if (b._id === currentBrandInLocal) {
    //   //               current_brand = b;
    //   //               break;
    //   //             }
    //   //           }
    //   //         }
    //   //       }
    //   //       dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
    //   //       if (brand_array.length === 0) {
    //   //         navigate("/inactiveUser");
    //   //       } else {
    //   //         navigate("/home/app");
    //   //       }
    //   //     }
    //   //   }
    //   // } catch (error) {
    //   //   setLoading(false);
    //   //   NotificationManager.error(error.response.data.data.message, "Error", 3000);
    //   // }
  };


  return (
    <div className="login">
      <div className="login-left">
        <div className="leftText">
          {/* <h1 className="mb-0">
            <strong>AI enabled </strong>Growth
          </h1>
          <h1 className="mb-0">We make E-commerce Faster,</h1>
          <h1 className="mb-0">Simpler and Better</h1>
          <img src="/loginPageImg.jpg" alt="img" /> */}
        </div>
      </div>
      <div className="login-right">

        {
          loading && <div style={{ position: "absolute", left: "calc(75%) ", top: "calc(50%)" }}><Loader /></div>
        }

        <div className="login-loginContainer">
          <div style={{ display: "flex", justifyContent: 'center' }}>
            <img src={EnlyticalLogo} alt="logo" height={"80px"} />
          </div>
          <div className="login-loginContainerHeader">
            <h4 className="mb-1">Login</h4>
            <h5 className="mb-2">to continue with <span style={{ color: "var(--active-color)" }}>Enlytical.com</span></h5>
          </div>
          <hr />
          <form className="form">
            <div className="mb-3">
              <label className="mb-1 login-label">Email</label>
              <input
                type="email"
                name="email"
                className="form-control login-input"
                id="email"
                placeholder="name@example.com"
                onChange={handleInputChange}
                value={state.email}
              ></input>
            </div>
            <div style={{ position: 'relative' }} className="mb-3">
              <label className="mb-1 login-label">Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={state.password}
                placeholder="Password"
                style={{ paddingRight: '30px' }}
                onChange={handleInputChange}
                className="form-control login-input"
                id="password"
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '70%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  color: '#384D6C'
                }}
              >
                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
              <div>
                <button className="login-btn"
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading ? true : false}
                >Log In </button>
              </div>

              <div>
                <Link style={{ color: 'var(--active-color)', fontWeight: '600' }} to={"/forgetPassword"}>Forgot Password?</Link>
              </div>
              {/* <div>
                <Link style={{ color: 'var(--active-color)', fontWeight: '600' }} to={"/signup"}>Sign Up</Link>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
