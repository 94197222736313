import { useNavigate, Outlet, useLocation } from "react-router-dom";
import "./AppLayout.scss";
import enlyticalLogo from "../assets/EnlyticalLogo3.png";
import { useEffect, useState, useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import Header from "./layoutComponents/Header";
import Content from "./layoutComponents/Content";
import Left from "./layoutComponents/Left";
import Right from "./layoutComponents/Right";
import "../appLayout/layoutComponents/Left.scss";
import "../appLayout/layoutComponents/Right.scss";
import { createPortal } from 'react-dom';

import {
  AchivementsIcons,
  AddTargetsIcon,
  ComparativeReportIcon,
  DailyActionsIcon,
  DailyReportsIcon,
  HourlyBidsIcon,
  KeywordAsinIcon,
  DailyTargetsIcon,
  KeywordCategorizationIcon,
  KeywordTypeReportIcon,
  MonthlyBudgetIcon,
  MothlyDistributionIcon,
  NegativeTargetsIcon,
  PausedTargetsIcon,
  ProductMasterIcon,
  SearchVolumeIcon,
  SellerMasterIcon,
  SpCampaignsIcon,
  VisibilityCheckerIcon,
  AmsTrendsIcon,
} from "../assets/svgs";

import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { brandDetails, clearBranddetails } from "../redux/slices/clientSlice";
import { logout } from "../redux/slices/authSlice";
import { getUserColor } from "../utils/commonFunction";
import Loader from "../utils/commonComponents/Loader/Loader";
import MarketingStreamIcon from "../assets/svgs/MarketingStreamIcon";
import RetailReadinessIcon from "../assets/svgs/RetailReadinessIcon";
import ProductStatusIcon from "../assets/svgs/ProductStatusIcon";
import TotalSalesIcon from "../assets/svgs/TotalSalesIcon";
import { notificationToggle } from "../redux/slices/notificationSlice";
import NotificationCard from "../components/notificationManager/pages/NotificationCard";
import AMCAmazonTabIcon from "../assets/svgs/AMCAmazonTabIcon";
import AMCAudienceIcon from "../assets/svgs/AMCAudienceIcon";
import AMCAnalyticsIcon from "../assets/svgs/AMCAnalyticsIcon";
import axios from "axios";
import { BASE_URL_NEW } from "../appConstant";

const AppLayout = () => {
  const location = useLocation();
  const popupRef = useRef(null);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clientDetails = useAppSelector((state) => state.client);
  const { current_brand, brand_array, current_agency } = clientDetails;
  const user = useAppSelector((state) => state.user);
  // const notification = useAppSelector(state => state.notification);
  // const { isOpen } = notification;

  const [toggle, setToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);

  const [tab, setTab] = useState("dailyReports");
  const [notificationToggle, setNotificationToggle] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const hamburgerRef = useRef(null);

  const [sidebarList, setSidebarList] = useState({
    reports: false,
    Qcom: true,
    // Onboarding:true,
    automation: false,
    budget: false,
    master: false,
    tools: false,
    benchmarks: false,
    campaignManager: false,
    ms:false
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current?.contains(event.target)) {
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClose = () => {
    setAnchorEl("");
  };

  const handleToggle = () => {
    setToggle((prevState) => !prevState);
    setSearchQuery("");
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const handleMouseLeave = (event) => {
    if (window.innerWidth <= 930) { // Ensure it only applies in responsive mode
      setIsSidebarVisible(false);
    }
  };

  const handleBrandChange = (brand) => {
    localStorage.setItem("currentBrand", brand?.client_id);
    // setCurrentBrand(brand);
    dispatch(brandDetails({ current_brand: brand }));
    setToggle(false);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const searchResults =
    brand_array &&
    brand_array.filter((el) =>
      el.client_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const handleMyAccount = () => {
    navigate("/home/myAccount");
    handleClose();
  };
  const handleSubscription = () => {
    navigate("/home/subscription");
    handleClose();
  };
  const handleBrandOnboarding = () => {
    navigate("/home/brandOnboarding");
    handleClose();
  };

  const handleManagmentDetails = () => {
    navigate("/home/details");
    handleClose();
  };
  const handleOnboardBrand = () => {
    navigate("/home/onboard");
    handleClose();
  };

  const handleApprovals = () => {
    navigate("/home/approvals");
    handleClose();
  };

  const handleSidebarList = (e) => {
    if (e === Object.keys(sidebarList).find((key) => key === e)) {
      setSidebarList((prev) => ({ ...prev, [e]: !sidebarList[e] }));
    }
  };

  const handleNotification = () => {
    // dispatch(notificationToggle())
    setNotificationToggle((prev) => !prev)
  }



  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const client = useAppSelector(state => state.client)
  const [error, setError] = useState(null)










  // for Power BI dynamic dashboards names to show

  useEffect(() => {

    setIsLoading(true);
    axios.get(`${BASE_URL_NEW}/powerbi/reportsList/${current_brand?.client_id}`)
      .then((res) => {
        if (res.data.length === 0) {
          setError('No dashboard list present for this brand!!')
        } else {
          const sortdata = res.data.sort((a, b) => a?.sortOrder - b?.sortOrder)
          setList(sortdata);
          setError(null)
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      })
  }, [current_brand?.client_id])




  // <div className={`backdrop ${isSidebarVisible ? "visible" : ""}`} onClick={toggleSidebar}></div>
  return (
    <>
      { /*<div style={{ display: "flex" }}>*/}
      {brand_array?.length > 0 ? (
        <div style={{ display: "flex" }}>
          <div ref={hamburgerRef} className="hamburger-icon" onClick={toggleSidebar}>
            <i className="bi bi-list"></i>
          </div>

          { /* isSidebarVisible?<div className={`backdrop dashboardsLeft ${isSidebarVisible ? "visible" : ""}`}>works1</div>:<div>works</div> */}

          {/* <Left className="dashboardsLeft">*/}
          {/* classed are in Left tag
            className={(`backdrop dashboardsLeft ${isSidebarVisible ? "visible" : ""}`)} */}
          <div>
            <Left isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} >

              <div className="dashboardsLeft-logoHeader">
                <img className="dashboardsLeft-logo" src={enlyticalLogo} />
                <div ref={popupRef}>
                  {location.pathname !== "/home/details" &&
                    location.pathname !== "/home/myAccount" &&
                    location.pathname !== "/home/onboard" &&
                    location.pathname !== "/home/approvals" && (
                      <div className="currentBrandName" onClick={handleToggle}>
                        <span>{current_brand?.client_name}</span>
                        <span className="currentBrandNameIcon"><i className="bi bi-chevron-down stroke"></i></span>
                      </div>
                    )}
                  <div className="brandOption" style={{ display: toggle ? "block" : "none" }}>
                    <div className="selectBrandDetails1">
                      <h6>{current_brand?.client_name}</h6>
                      <div> ID: <span>{current_brand?.entity_id}</span></div>
                      <div> Marketplace: <span>{current_brand?.country}</span></div>
                    </div>
                    {brand_array?.length !== 1 && (
                      <div className="searchInputContainer">
                        <div className="brandSearchInputContainer">
                          <i style={{ color: "rgba(130, 163, 180, 1)" }} className="bi bi-search px-1"></i>
                          <input className="brandSearchInput" type="text" value={searchQuery} onChange={handleInputChange} placeholder="search" />
                        </div>
                      </div>
                    )}
                    <hr style={{ marginBottom: "10px", marginTop: "0px" }} />
                    <div className="brandListContainer">
                      {brand_array?.length > 0 &&
                        searchResults?.map((brand, i) => {
                          return (
                            <div className={`brandList-${brand === current_brand ? "active" : ""}`} key={i} onClick={() => handleBrandChange(brand)}>
                              {brand.client_name.charAt(0).toUpperCase() + brand.client_name.slice(1)}
                              <div style={{ fontSize: "10px" }}>{brand.country}</div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>








              <div className="dashboardsLeft-sidebar">








                <div className="dashboardsLeft-sidebarUnit">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleSidebarList("Qcom")}
                  >
                    <h6 className="dashboardsLeft-heading">Onboarding</h6>
                    <i
                      className={
                        sidebarList.Qcom
                          ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up"
                          : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"
                      }
                    ></i>
                  </div>
                  <hr style={{ margin: "0px", color: "rgba(6, 72, 106, 1)" }} />
                  <div
                    className={`dashboardsLeft-subContainer ${sidebarList.Qcom ? "dashboardsLeft-visible" : ""
                      }`}
                  >
                    <NavLink
                      to="/home/brandOnboarding"
                      onClick={() => setTab("brandOnboarding")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={
                          tab === "brandOnboarding" ? "icon_back" : "icon_sim"
                        }
                      >
                        <ProductStatusIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Brand Onboarding
                      </span>
                    </NavLink>
                    <NavLink
                      to="/Qcom/productStatus"
                      onClick={() => setTab("productStatus")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={
                          tab === "productStatus" ? "icon_back" : "icon_sim"
                        }
                      >
                        <ProductStatusIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Product Status Qcom
                      </span>
                    </NavLink>
                    <NavLink
                      to="/Qcom/productMaster"
                      onClick={() => setTab("product")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={tab === "product" ? "icon_back" : "icon_sim"}
                      >
                        <ProductMasterIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Product Master
                      </span>
                    </NavLink>
                    <NavLink
                      to="/Qcom/competitivemaster"
                      onClick={() => setTab("competitivemaster")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={tab === "competitivemaster" ? "icon_back" : "icon_sim"}
                      >
                        <ProductMasterIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Competitive Master
                      </span>
                    </NavLink>
                    <NavLink
                      to="/Qcom/productHarmonization"
                      onClick={() => setTab("productHarmonization")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={tab === "productHarmonization" ? "icon_back" : "icon_sim"}
                      >
                        <ProductMasterIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Product Harmonization
                      </span>
                    </NavLink>
                  </div>
                </div>



                <div className="dashboardsLeft-sidebarUnit">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleSidebarList("reports")}
                  >
                    <h6 className="dashboardsLeft-heading">Reports</h6>
                    <i
                      className={
                        sidebarList.reports
                          ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up"
                          : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"
                      }
                    ></i>
                  </div>
                  <hr style={{ margin: "0px", color: "rgba(6, 72, 106, 1)" }} />


                  <div
                    className={`dashboardsLeft-subContainer ${sidebarList.reports ? "dashboardsLeft-visible" : ""
                      }`}
                  >

                    {/* <NavLink
                  //   to="/reports/powerbi"
                  //   onClick={() => setTab("powerbi")}
                  //   className="dashboardsLeft-subHeadingUnit"
                  // >
                  //   <span
                  //     className={
                  //       tab === "powerbi" ? "icon_back" : "icon_sim"
                  //     }
                  //   >
                  //     <DailyReportsIcon tab={tab} />
                  //   </span>{" "}
                  //   <span className="dashboardsLeft-subHeading">
                  //     PowerBI Reporting
                  //   </span>
                  // </NavLink>
                    */}


                    {list.map((report) => (

                      <NavLink
                        key={report?._id}
                        to={`/reports/powerbi/${report?._id}`}
                        onClick={() => setTab(report?._id)}
                        className="dashboardsLeft-subHeadingUnit"
                      >
                        <span className={tab === report?._id ? "icon_back" : "icon_sim"}>
                          <DailyReportsIcon tab={tab} />
                        </span>
                        <span className="dashboardsLeft-subHeading">
                          {report?.dashboard_name}
                        </span>
                      </NavLink>
                    ))}




                  </div>






                </div>








                <div className="dashboardsLeft-sidebarUnit">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleSidebarList("tools")}
                  >
                    <h6 className="dashboardsLeft-heading">AMC</h6>
                    <i
                      className={
                        sidebarList.tools
                          ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up"
                          : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"
                      }
                    ></i>
                  </div>
                  <hr style={{ margin: "0px" }} />
                  <div
                    className={`dashboardsLeft-subContainer ${sidebarList.tools ? "dashboardsLeft-visible" : ""
                      }`}
                  >

                    {/* <NavLink
                      to="/tools/monitoringSystem"
                      onClick={() => setTab("monitoringSystem")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={
                          tab === "monitoringSystem" ? "icon_back" : "icon_sim"
                        }
                      >
                        <KeywordTypeReportIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Monitoring System
                      </span>
                    </NavLink> */}

                    {/*
                  <NavLink
                    to="/tools/productAssortment"
                    onClick={() => setTab("productAssortment")}
                    className="dashboardsLeft-subHeadingUnit"
                  >
                    <span
                      className={
                        tab === "productAssortment" ? "icon_back" : "icon_sim"
                      }
                    >
                      <KeywordTypeReportIcon tab={tab} />
                    </span>{" "}
                    <span className="dashboardsLeft-subHeading">
                      Product Assortment
                    </span>
                  </NavLink>
                  */}

                    <NavLink
                      to="/tools/amc"
                      onClick={() => setTab("amc-reporting")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={
                          tab === "amc-reporting" ? "icon_back" : "icon_sim"
                        }
                      >
                        <AMCAnalyticsIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Analytics
                      </span>
                    </NavLink>

                    <NavLink
                      to="/tools/amc-audience"
                      onClick={() => setTab("amc-audience")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={
                          tab === "amc-audience" ? "icon_back" : "icon_sim"
                        }
                      >
                        <AMCAudienceIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Audiences
                      </span>
                    </NavLink>

                    {/*
                  <div className="dashboardsLeft-sidebarUnit">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleSidebarList("master")}
                  >
                    <h6 className="dashboardsLeft-heading">Amazon Marketing Cloud</h6>
                    <i
                      className={
                        sidebarList.master
                          ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up"
                          : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"
                      }
                    ></i>
                  </div>
                  <hr style={{ margin: "0px", color: "rgba(6, 72, 106, 1)" }} />
                  <div
                    className={`dashboardsLeft-subContainer ${sidebarList.master ? "dashboardsLeft-visible" : ""
                      }`}
                  >
                  <NavLink
                  to="/tools/amc"
                  onClick={() => setTab("amc-reporting")}
                  className="dashboardsLeft-subHeadingUnit"
                >
                  <span
                    className={
                      tab === "amc-reporting" ? "icon_back" : "icon_sim"
                    }
                  >
                    <KeywordTypeReportIcon tab={tab} />
                  </span>{" "}
                  <span className="dashboardsLeft-subHeading">
                    Amc Analytics
                  </span>
                </NavLink>
                    <NavLink
                      to="/tools/amc-audiences"
                      onClick={() => setTab("amc-audiences")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={tab === "amc-audiences" ? "icon_back" : "icon_sim"}
                      >
                        <AMCAmazonTabIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Audiences
                      </span>
                    </NavLink>
                  </div>
                </div>
                  */}


                    {/* <NavLink to='/tools/searchVolume' className='dashboardsLeft-subHeadingUnit'><SearchVolumeIcon /> <span className="dashboardsLeft-subHeading">Search Volume</span></NavLink>
                                    <NavLink to='/tools/keywordAsinIndex' className='dashboardsLeft-subHeadingUnit'><KeywordAsinIcon /> <span className="dashboardsLeft-subHeading">Keyword ASIN Indexes</span></NavLink>
                                    <NavLink to='/tools/visibilityChecker' className='dashboardsLeft-subHeadingUnit'><VisibilityCheckerIcon /> <span className="dashboardsLeft-subHeading">Visibility Checker</span></NavLink> */}
                  </div>
                </div>


                {/* marketing stream */}
                <div className="dashboardsLeft-sidebarUnit">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleSidebarList("ms")}
                  >
                    <h6 className="dashboardsLeft-heading">Marketing Stream</h6>
                    <i
                      className={
                        sidebarList.ms
                          ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up"
                          : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"
                      }
                    ></i>
                  </div>
                  <hr style={{ margin: "0px" }} />
                  <div
                    className={`dashboardsLeft-subContainer ${sidebarList.ms ? "dashboardsLeft-visible" : ""
                      }`}
                  >

                    <NavLink
                      to="/ms/report"
                      onClick={() => setTab("monitoringSystem")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={
                          tab === "monitoringSystem" ? "icon_back" : "icon_sim"
                        }
                      >
                        <KeywordTypeReportIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Report
                      </span>
                    </NavLink>

                   
                  <NavLink
                    to="/ms/hourly"
                    onClick={() => setTab("productAssortment")}
                    className="dashboardsLeft-subHeadingUnit"
                  >
                    <span
                      className={
                        tab === "productAssortment" ? "icon_back" : "icon_sim"
                      }
                    >
                      <KeywordTypeReportIcon tab={tab} />
                    </span>{" "}
                    <span className="dashboardsLeft-subHeading">
                      Hourly Report
                    </span>
                  </NavLink>


                    {/*
                  <div className="dashboardsLeft-sidebarUnit">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleSidebarList("master")}
                  >
                    <h6 className="dashboardsLeft-heading">Amazon Marketing Cloud</h6>
                    <i
                      className={
                        sidebarList.master
                          ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up"
                          : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"
                      }
                    ></i>
                  </div>
                  <hr style={{ margin: "0px", color: "rgba(6, 72, 106, 1)" }} />
                  <div
                    className={`dashboardsLeft-subContainer ${sidebarList.master ? "dashboardsLeft-visible" : ""
                      }`}
                  >
                  <NavLink
                  to="/tools/amc"
                  onClick={() => setTab("amc-reporting")}
                  className="dashboardsLeft-subHeadingUnit"
                >
                  <span
                    className={
                      tab === "amc-reporting" ? "icon_back" : "icon_sim"
                    }
                  >
                    <KeywordTypeReportIcon tab={tab} />
                  </span>{" "}
                  <span className="dashboardsLeft-subHeading">
                    Amc Analytics
                  </span>
                </NavLink>
                    <NavLink
                      to="/tools/amc-audiences"
                      onClick={() => setTab("amc-audiences")}
                      className="dashboardsLeft-subHeadingUnit"
                    >
                      <span
                        className={tab === "amc-audiences" ? "icon_back" : "icon_sim"}
                      >
                        <AMCAmazonTabIcon tab={tab} />
                      </span>{" "}
                      <span className="dashboardsLeft-subHeading">
                        Audiences
                      </span>
                    </NavLink>
                  </div>
                </div>
                  */}


                    {/* <NavLink to='/tools/searchVolume' className='dashboardsLeft-subHeadingUnit'><SearchVolumeIcon /> <span className="dashboardsLeft-subHeading">Search Volume</span></NavLink>
                                    <NavLink to='/tools/keywordAsinIndex' className='dashboardsLeft-subHeadingUnit'><KeywordAsinIcon /> <span className="dashboardsLeft-subHeading">Keyword ASIN Indexes</span></NavLink>
                                    <NavLink to='/tools/visibilityChecker' className='dashboardsLeft-subHeadingUnit'><VisibilityCheckerIcon /> <span className="dashboardsLeft-subHeading">Visibility Checker</span></NavLink> */}
                  </div>
                </div>

                {/* <div className="dashboardsLeft-sidebarUnit">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => handleSidebarList('benchmarks')}>
                                    <h6 className="dashboardsLeft-heading">Benchmarks</h6>
                                    <i className={sidebarList.benchmarks ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up" : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"}></i>
                                </div>
                                <hr style={{ margin: '0px' }} />
                                <div className={`dashboardsLeft-subContainer ${sidebarList.benchmarks ? "dashboardsLeft-visible" : ''}`}>
                                    <NavLink to='/benchmarks/pastTrends' className='dashboardsLeft-subHeadingUnit'><AmsTrendsIcon /> <span className="dashboardsLeft-subHeading">AMS ACOS Trends</span></NavLink>
                                </div>
                            </div> */}

                {/* <div className="dashboardsLeft-sidebarUnit">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => handleSidebarList('campaignManager')}>
                                    <h6 className="dashboardsLeft-heading">Campaign Manager</h6>
                                    <i className={sidebarList.campaignManager ? "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-up" : "bi bi-caret-down-fill dashboardsLeft-icon dashboardsLeft-down"}></i>
                                </div>
                                <hr style={{ margin: '0px' }} />
                                <div className={`dashboardsLeft-subContainer ${sidebarList.campaignManager ? "dashboardsLeft-visible" : ''}`}>
                                    <NavLink to='/campaignManager/spCampaigns' className='dashboardsLeft-subHeadingUnit'><SpCampaignsIcon /> <span className="dashboardsLeft-subHeading">SP Campaigns</span></NavLink>
                                    <NavLink to='/campaignManager/addTargets' className='dashboardsLeft-subHeadingUnit'><AddTargetsIcon /> <span className="dashboardsLeft-subHeading">Add Targets</span></NavLink>
                                    <NavLink to='/campaignManager/negativeTargets' className='dashboardsLeft-subHeadingUnit'><NegativeTargetsIcon /> <span className="dashboardsLeft-subHeading">Negative Targets</span></NavLink>
                                </div>
                            </div> */}
              </div>

              <hr />
              {
                // <div style={{ position: 'relative' }}>
                //   <div onClick={handleNotification}><i className="bi bi-bell-fill"></i> &nbsp; Notifications</div>
                //   {notificationToggle &&
                //     createPortal(
                //     <div style={{ zIndex: 9999, position: 'absolute', left: '200px',bottom:'80px' ,backgroundColor: 'white' }}>
                //       {/*<NotificationCard /> */}  
                //      </div>,
                //     document.body /* renders this element directly into body */
                //     )
                //   }
                // </div>
                // <hr />
              }

              <div className="dashboardsLeft-bottom">
                <div
                  className="userIcon"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <div className="userBox">
                    <Avatar
                      sx={{
                        height: 30,
                        width: 30,
                        backgroundColor: getUserColor(current_brand?.access_role),
                      }}
                    >
                      <span style={{ fontSize: "1rem", color: "#000000" }}>
                        {user?.first_name?.[0]?.toUpperCase() +
                          user?.last_name?.[0]?.toUpperCase()}
                      </span>
                    </Avatar>
                    <div className="userSubBox">
                      <span
                        style={{
                          fontSize: "0.8rem",
                          textTransform: "capitalize",
                          fontWeight: "500",
                        }}
                      >
                        {user?.first_name + " " + user?.last_name}
                      </span>
                      <span style={{ fontSize: "0.65rem" }}>{user?.email}</span>
                    </div>
                    <div className="userDown">
                      <i className="bi bi-three-dots-vertical stroke"></i>
                    </div>

                  </div>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                  transformOrigin={{ horizontal: "bottom", vertical: "left" }}
                  anchorOrigin={{ horizontal: "bottom", vertical: "right" }}
                >
                  <MenuItem onClick={handleMyAccount}>
                    <span>My Account</span>
                  </MenuItem>
                  <MenuItem onClick={handleSubscription}>
                    <span>Subscription</span>
                  </MenuItem>
                  <MenuItem onClick={handleBrandOnboarding}>
                    <span>Brand Onboarding</span>
                  </MenuItem>
                  <MenuItem onClick={handleManagmentDetails}>
                    <span>Management Details</span>
                  </MenuItem>
                  {(current_brand?.access_role === "AGENCY_ADMIN" ||
                    current_brand?.access_role === "BRAND_MANAGER") && (
                      <MenuItem onClick={handleApprovals}>
                        <span>Approvals</span>
                      </MenuItem>
                    )}
                  <hr style={{ margin: "5px 0" }} />
                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/login");
                      dispatch(logout());
                      dispatch(clearBranddetails());
                    }}
                  >
                    <button className="logout-button">
                      <span className="logout-buttonicon">
                        <LogoutIcon />
                      </span>
                      <span>Logout</span>
                    </button>
                  </MenuItem>
                </Menu>
              </div>
            </Left>
          </div>
          <Right className="dashboardRight">
            {/* <Header className='header'> */}
            {/* <div> */}
            {/* {
                            <div className="selectAgencyandBrand" ref={popupRef}>
                                <div>
                                    {
                                        user_domain === 'enlytical_admin' &&
                                        <div className="agency_select_container">
                                            <div className="currentBrandName" onClick={handleToggleAgency}>{current_agency.name} <span><i className={toggleAgency ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i></span></div>
                                            <div className="agencyOption" style={{ display: toggleAgency ? 'block' : 'none' }}>
                                                <div className="searchInputContainer">
                                                    <div className="brandSearchInputContainer">
                                                        <i className="bi bi-search px-1"></i>
                                                        <input className="brandSearchInput" type="text" value={searchQueryAgency} onChange={handleInputChangeAgency} placeholder="search" />
                                                    </div>
                                                </div>
                                                <hr style={{ marginBottom: '10px', marginTop: '0px' }} />
                                                <div className="brandListContainer">
                                                    {agency_array.length > 0 &&
                                                        searchResultsAgency.map((el, i) => {
                                                            return (
                                                                <div
                                                                    className={`brandList-${el._id === currentAgency ? 'active' : ""}`}
                                                                    key={i}
                                                                    onClick={() => agencyChangeHandler(el)}
                                                                >{el.name}
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        } */}
            {/* <div style={{ position: 'relative' }}>
                            <div className="agency">
                                <div><img src={current_agency?.logo} height={40} width={120} alt="" /></div>
                                <button className="agencyChangebtn" onClick={() => setToggleAgency(prev => !prev)}><i class="bi bi-chevron-down"></i></button>
                            </div>
                            <div style={{
                                display: toggleAgency ? 'block' : 'none', position: 'absolute',
                                backgroundColor: '#fff', boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", zIndex: 1
                            }}>
                                {
                                    searchResultsAgency?.map((agency) => {
                                        return <div className="agencyArrBox" key={agency._id}>
                                            <div onClick={() => handleAgencyChange(agency)} className="agencyEach">{agency?.name}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div> */}
            {/* </div> */}

            {/* </Header> */}
            {/* <Content className="dashboardRightContent"> */}
            <Outlet />
            {/* </Content> */}
          </Right>
        </div>
      ) : (
        <div style={{ position: "absolute", left: "50%", top: "50%" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default AppLayout;
