import React, { useEffect, useState } from "react";
import BlinkitIcon from "../../../assets/svgs/BlinkitIcon";
import ZeptoIcon from "../../../assets/svgs/ZeptoIcon";
import InstamartIcon from "../../../assets/svgs/InstamartIcon";
import axios from "axios";
import { BASE_URL_NEW } from "../../../appConstant";
import LoaderSkeletonTable from "../../../utils/commonComponents/Loader/LoaderSkeletonTable";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../redux/hook";
import LoaderSkeletonHarmonization from "../../../utils/commonComponents/Loader/LoaderSkeletonHarmonization";
// import "./ProductHarmonization.scss";



interface Product {
  UID: string;
  platformName: string;
  name: string;
  Category: string;
  [key: string]: any; // To account for other possible properties
}

interface GroupedProduct {
  Blinkit: Product | null;
  Zepto: Product | null;
  Instamart: Product | null;
}


interface ProductData {
  harmonized_id: string;
  internal_category: string;
  blinkit: {
    platform_code: string;
    short_name: string;
    pname: string;
    product_pic_url: string;
  };
  zepto: {
    platform_code: string;
    short_name: string;
    pname: string;
    product_pic_url: string;
  };
  instamart: {
    platform_code: string;
    short_name: string;
    pname: string;
    product_pic_url: string;
  };
}



const ProductHarmonization = () => {
  const client = useAppSelector(state => state.client);
  const { current_brand } = client
  const [editCard, setEditCard] = useState(false);
  // const [isActive, setIsActive] = useState("");
  const [editCardData1, setEditCardData1] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  const [selectedHarmonizationId,setSelectedHarmonizationId]=useState(null);
  const [data, setData] = useState<ProductData[]>([])

  const [filteredDataOriginal, setFilteredDataOriginal] = useState<ProductData[]>([]);

  const [isActive, setIsActive] = useState<"match" | "partial-match" | "unmatch" | "">("");




  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL_NEW}/qcomm/product-harmonization?client_name=${current_brand?.client_name?.toLowerCase()}`)
      .then((res: any) => {
        const rawData = res.data;

          // Normalize data
          const formattedData = rawData.map((item: any) => ({
            harmonized_id: item.harmonized_id,
            internal_category: item.internal_category,
            blinkit: item["blinkit"] || {
              platform_code: "",
              short_name: "",
              pname: "",
              product_pic_url: "",
            },
            zepto: item["zepto"] || {
              platform_code: "",
              short_name: "",
              pname: "",
              product_pic_url: "",
            },
            instamart: item["swiggy instamart"] || {
              platform_code: "",
              short_name: "",
              pname: "",
              product_pic_url: "",
            },
          }));

          // Sort data based on the availability of fields
      const sortedData = [...formattedData].sort((a, b) => {
        const aCount = [a.blinkit.pname, a.zepto.pname, a.instamart.pname].filter(Boolean).length;
        const bCount = [b.blinkit.pname, b.zepto.pname, b.instamart.pname].filter(Boolean).length;
        return bCount - aCount; // Higher availability comes first
      });

        setData(sortedData);
        // setFilteredDataOriginal(formattedData);
        setFilteredDataOriginal(sortedData);
        
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [current_brand]);


  const filterData = (filterType: "match" | "partial-match" | "unmatch" | "") => {
    let filteredData = [];

    if (filterType === "match") {
      // All three (blinkit, zepto, instamart) have data
      filteredData = data.filter(
        (item) =>
          item.blinkit.platform_code &&
          item.zepto.platform_code &&
          item.instamart.platform_code
      );
    } else if (filterType === "partial-match") {
      // Any two have data
      filteredData = data.filter(
        (item) =>
          (item.blinkit.platform_code && item.zepto.platform_code && !item.instamart.platform_code) ||
          (item.blinkit.platform_code && item.instamart.platform_code && !item.zepto.platform_code) ||
          (item.zepto.platform_code && item.instamart.platform_code && !item.blinkit.platform_code)
      );
    } else if (filterType === "unmatch") {
      // Only one has data
      filteredData = data.filter(
        (item) =>
          (item.blinkit.platform_code && !item.zepto.platform_code && !item.instamart.platform_code) ||
          (!item.blinkit.platform_code && item.zepto.platform_code && !item.instamart.platform_code) ||
          (!item.blinkit.platform_code && !item.zepto.platform_code && item.instamart.platform_code)
      );
    } else {
      // Default: Show all data
      filteredData = data;
    }

    setFilteredDataOriginal(filteredData);
  };



  // const handleFilter = (filterType: string) => {
  //   if (filterType === isActive) {
  //     setIsActive('');
  //     setFilteredData1([
  //       ...data.match,
  //       ...data.partialMatch,
  //       ...data.unmatch,
  //     ]);
  //   } else {
  //     setIsActive(filterType);
  //     if (filterType === 'match') {
  //       setFilteredData(data.match);
  //     } else if (filterType === 'partial-match') {
  //       setFilteredData(data.partialMatch);
  //     } else if (filterType === 'unmatch') {
  //       setFilteredData(data.unmatch);
  //     }
  //   }
  // };
  



  const [filteredData, setFilteredData] = useState(editCardData1);

  const [activeEditProduct, setActiveEditProduct] = React.useState<number | null>(null);



  const handleEditClick = (productId: any) => {
    // setEditCardData([product]);
    setActiveEditProduct((prev) => (prev === productId ? null : productId));
    // setEditCard(true);
  };

  const handleEditIconClick = (category:any,platform:any,harmonizationid:any) => {

    setSelectedHarmonizationId(harmonizationid);
    setIsLoadingEdit(true);
      axios
        .get(`${BASE_URL_NEW}/qcomm/product-harmonization/products?client_name=${current_brand?.client_name?.toLowerCase()}&platform=${platform}&category=${category}`)
        .then((res: any) => {
          const rawData = res.data;
          setEditCardData1(rawData)
          setFilteredData(rawData);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoadingEdit(false);
        });

  };

  const handleEditCardClick= (data: any) => {
    // console.log('target key',data);

    //  api call
    //  set Edit Data 
    setIsLoading(true);

      axios
        .patch(`${BASE_URL_NEW}/qcomm/product-harmonization/${data.platform_code}`,
          {
            harmonized_id: selectedHarmonizationId, // Body data   // where to get this from
          }
        )
        .then((res: any) => {
          // toast('successfully updated')
        })
        .catch((error: any) => {
          console.error(error);
          // toast('not updated')
        })
        .finally(() => {
          setIsLoading(false);
        });

        handleEditClick(false);

  };


  const handleChange = (e: any) => {
    setSearchQuery(e.target.value)
    const filtered = editCardData1.filter((product:any) =>
      product.platform_code.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };


  const handleFilter = (filterType: any) => {
    setIsActive(filterType);
  
    if (!filterType) {
      // Reset to default when no filter is active
      setFilteredDataOriginal(data);
      return;
    }
  
    const filtered = data.filter((item:any) => {
      const filledFields = [item.blinkit, item.zepto, item.instamart].filter(Boolean).length;
  
      if (filterType === 'match') return filledFields === 3; // All three have data
      if (filterType === 'partial-match') return filledFields === 2; // Any two have data
      if (filterType === 'unmatch') return filledFields === 1; // Only one has data
  
      return false;
    });
  
    setFilteredDataOriginal(filtered);
  };
  

  return (
    <>
      <div className={editCard ? "product-harmoniz" : ''}>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <h3>Product Harmonization</h3>
          <div className="harmonization-block">
            <div className="summary-block">Product Summary</div>
            <div
              className={`match-block match-button ${isActive === 'match' ? 'active' : ''}`}
              onClick={() => setIsActive(isActive === 'match' ? '' : 'match')}>
              <div className="match">Match</div>
              <div>3</div>
            </div>
            <div className={`unmatch-block unmatch-button ${isActive === 'unmatch' ? 'active' : ''}`}
              onClick={() => setIsActive(isActive === 'unmatch' ? '' : 'unmatch')}>
              <div className="unmatch">Unmatch</div>
              <div>1</div>
            </div>
          </div>
        </div> */}


        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <h3>Product Harmonization</h3>
          <div className="harmonization-block">
            <div className="summary-block">Product Summary</div>
            <div
              className={`match-block match-button ${isActive === 'match' ? 'active' : ''}`}
              // onClick={() => setIsActive(isActive === 'match' ? '' : 'match')}
              // onClick={()=> handleFilter(isActive === 'match' ? '' : 'match')}
              onClick={() => {
                // const newFilter = isActive === 'match' ? '' : 'match';
                // setIsActive(newFilter);
                // handleFilter(newFilter); // Call handleFilter with the appropriate filter
                setIsActive(isActive === "match" ? "" : "match");
                filterData(isActive === "match" ? "" : "match");
              }}
              >
              <div className="match">Match</div>
              <div></div>
            </div>
            <div className={`partial-match-block partial-match-button ${isActive === 'partial-match' ? 'active' : ''}`}
              // onClick={() => setIsActive(isActive === 'partial-match' ? '' : 'partial-match')}
              // onClick={()=> handleFilter(isActive === 'match' ? '' : 'match')}
              onClick={() => {
                // const newFilter = isActive === 'partial-match' ? '' : 'partial-match';
                // setIsActive(newFilter);
                // handleFilter(newFilter); // Call handleFilter with the appropriate filter

                setIsActive(isActive === "partial-match" ? "" : "partial-match");
                filterData(isActive === "partial-match" ? "" : "partial-match");
              }}
              >
              
              <div className="partial-match">Partial Matched</div>
              <div></div>
            </div>
            <div className={`unmatch-block unmatch-button ${isActive === 'unmatch' ? 'active' : ''}`}
              // onClick={() => setIsActive(isActive === 'unmatch' ? '' : 'unmatch')}
              /* onClick={()=> handleFilter(isActive === 'match' ? '' : 'match')} */
              onClick={() => {
                // const newFilter = isActive === 'unmatch' ? '' : 'unmatch';
                // setIsActive(newFilter);
                // handleFilter(newFilter); // Call handleFilter with the appropriate filter

                setIsActive(isActive === "unmatch" ? "" : "unmatch");
                filterData(isActive === "unmatch" ? "" : "unmatch");
              }}
              >
              <div className="unmatch">Unmatch</div>
              <div></div>
            </div>
          </div>
        </div>




        {/* new one harmonization  */}

        {isLoading?<LoaderSkeletonHarmonization/>:

        <div className="product-harmonization1">
          <div className="product-cards">



            {/* {data.map((it: any) => (<> */}
            {filteredDataOriginal.map((it: any) => (<>



              {/* Fixed Column for Blinkit */}
              <div className="column">

                <div className="product-card" key={it.harmonized_id}>
                  <div className="card-section image-section">
                    <img src={it.blinkit.product_pic_url} alt={it.blinkit.short_name} />
                  </div>
                  <div className="card-section details-section">
                    {it.blinkit.pname ?
                      <div className="icons">
                        <BlinkitIcon />
                      </div> : ''
                    }
                    <div className="product-info">
                      <div className="product-name">{it.blinkit.short_name}</div>
                      {it.blinkit.pname ?
                          <>
                        <div className="product-category">{it.internal_category}</div>
                        <div className="product-sku">
                          SKU: {it.blinkit.platform_code.length > 9
                            ? `${it.blinkit.platform_code.slice(0, 9)}...`
                            : it.blinkit.platform_code}
                          <div className="edit-iconback">
                            <div
                              className="edit-icon"
                              onClick={() => {handleEditClick(it.blinkit.platform_code)
                                handleEditIconClick(it.internal_category,'blinkit',it.harmonized_id)}
                              }
                            >
                              <i className="bi bi-pencil"></i>
                            </div>
                          </div>
                        </div>
                        </>
                        : ""}
                    </div>
                  </div>



                  {/* Editable Card */}
                  {activeEditProduct === it.blinkit.platform_code &&

                    <div className="edit-card">
                      {/* // search bar  */}
                      <div className="search-bar" style={{ marginBottom: '1rem' }}>
                        <div>
                          <i className="bi bi-search search-icon" ></i>
                          <input
                            type="text"
                            placeholder="ENTER ASIN to add Product"
                            value={searchQuery}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <button className="close-btn" onClick={() => handleEditClick(false)}>
                        ✖
                      </button>
                      { isLoadingEdit?<LoaderSkeletonTable length="4"/>:
                        filteredData.map((data: any) => (
                          <>
                            <div className="product-card select-Edit-Card" key={data.id}  onClick={()=>handleEditCardClick(data)}>
                              <div className="card-section image-section">
                                <img src={data.product_pic_url} alt={data.short_name} />
                              </div>

                              {/* Middle Section: Details */}
                              <div className="card-section details-section">
                                <div className="icons">
                                 <BlinkitIcon />
                                </div>
                                <div className="product-info">
                                  <div className="product-name">{data.short_name}</div>
                                  <div className="product-sku">
                                    SKU: {data.platform_code}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </>
                        ))
                      }
                    </div>

                  }





                </div>

              </div>


              {/* Fixed Column for Zepto */}
              <div className="column">

                <div className="product-card" key={it.harmonized_id}>
                  <div className="card-section image-section">
                    <img src={it.zepto.product_pic_url} alt={it.zepto.short_name} />
                  </div>
                  <div className="card-section details-section">
                    {it.zepto.pname ?
                      <div className="icons">
                        <ZeptoIcon />
                      </div> : ''
                    }
                    <div className="product-info">
                      <div className="product-name">{it.zepto.short_name}</div>
                      {it.zepto.pname ?
                      <>
                      <div className="product-category">{it.internal_category}</div>
                        <div className="product-sku">
                          SKU: {it.zepto.platform_code.length > 9
                            ? `${it.zepto.platform_code.slice(0, 9)}...`
                            : it.zepto.platform_code}
                          <div className="edit-iconback">
                            <div
                              className="edit-icon"
                              onClick={() => {handleEditClick(it.zepto.platform_code)
                                              handleEditIconClick(it.internal_category,'zepto',it.harmonized_id)}
                              }
                            >
                              <i className="bi bi-pencil"></i>
                            </div>
                          </div>
                        </div></> : ''
                      }
                    </div>
                  </div>


                  {/* Editable Card */}
                  {activeEditProduct === it.zepto.platform_code &&

                    <div className="edit-card">
                      {/* // search bar  */}
                      <div className="search-bar" style={{ marginBottom: '1rem' }}>
                        <div>
                          <i className="bi bi-search search-icon" ></i>
                          <input
                            type="text"
                            placeholder="ENTER ASIN to add Product"
                            value={searchQuery}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <button className="close-btn" onClick={() => handleEditClick(false)}>
                        ✖
                      </button>

                      { isLoadingEdit?<LoaderSkeletonTable length="4"/>:
                        filteredData.map((data: any) => (
                          <>
                            <div className="product-card select-Edit-Card" key={data.id}  onClick={()=>handleEditCardClick(data)}>
                              <div className="card-section image-section">
                                <img src={data.product_pic_url} alt={data.short_name} />
                              </div>

                              {/* Middle Section: Details */}
                              <div className="card-section details-section">
                                <div className="icons">
                                  <ZeptoIcon />
                                </div>
                                <div className="product-info">
                                  <div className="product-name">{data.short_name}</div>
                                  <div className="product-sku">
                                    SKU: {data.platform_code}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </>
                        ))
                      }
                    </div>

                  }



                </div>

              </div>



              {/* Fixed Column for Instamart */}
              <div className="column">
                
                    <div className="product-card" key={it.harmonized_id}>
                      <div className="card-section image-section">
                        <img src={it.instamart.product_pic_url} alt={it.instamart.short_name} />
                      </div>
                      <div className="card-section details-section">
                        {it.instamart.pname ?
                          <div className="icons">
                            <InstamartIcon />
                          </div> : ""
                        }
                        <div className="product-info">
                          <div className="product-name">{it.instamart.short_name}</div>
                          {it.instamart.pname?
                          <>
                          <div className="product-category">{it.internal_category}</div>
                          <div className="product-sku">
                            SKU: {it.instamart.platform_code.length > 9
                          ? `${it.instamart.platform_code.slice(0, 9)}...`
                          : it.instamart.platform_code}
                            <div className="edit-iconback">
                              <div
                                className="edit-icon"
                                onClick={() => {handleEditClick(it.instamart.platform_code)
                                                handleEditIconClick(it.internal_category,'swiggy instamart',it.harmonized_id)}
                                }
                              >
                                <i className="bi bi-pencil"></i>
                              </div>
                            </div>
                          </div></>:''}
                        </div>
                      </div>




                      {/* Editable Card */}
                      {activeEditProduct === it.instamart.platform_code &&

                        <div className="edit-card">
                          {/* // search bar  */}
                          <div className="search-bar" style={{ marginBottom: '1rem' }}>
                            <div>
                              <i className="bi bi-search search-icon" ></i>
                              <input
                                type="text"
                                placeholder="ENTER ASIN to add Product"
                                value={searchQuery}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <button className="close-btn" onClick={() => handleEditClick(false)}>
                            ✖
                          </button>

                          { isLoadingEdit?<LoaderSkeletonTable length="4"/>:
                            filteredData.map((data: any) => (
                              <>
                                <div className="product-card select-Edit-Card" key={data.id}  onClick={()=>handleEditCardClick(data)}>
                                  <div className="card-section image-section">
                                    <img src={data.product_pic_url} alt={data.short_name} />
                                  </div>

                                  {/* Middle Section: Details */}
                                  <div className="card-section details-section">
                                    <div className="icons">
                                      <InstamartIcon />
                                    </div>
                                    <div className="product-info">
                                      <div className="product-name">{data.short_name}</div>
                                      <div className="product-sku">
                                        SKU: {data.platform_code}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </>
                            ))
                          }
                        </div>

                      }



                    </div>
                
              </div>



            </>))}

          </div>

        </div>

        }


      </div>

    </>
  );
};

export default ProductHarmonization;


