import "./ProductKpiQcom.scss";
import ZeptoQcomIcon from "../../../../assets/svgs/ZeptoQcomIcon";
import BlinkitQcomIcon from "../../../../assets/svgs/BlinkitQcomIcon";
import { useEffect, useState } from "react";
import { BASE_URL_NEW } from '../../../../appConstant';
import axios from 'axios';
import { isTemplateLiteralToken } from "typescript";
import LoaderSkeletonGraph from "../../../../utils/commonComponents/Loader/LoaderSkeletonGraph";
import InstamartQcomIcon from "../../../../assets/svgs/InstamartQcomIcon";
import { useAppSelector } from "../../../../redux/hook";


const ProductKPIQcom = () => {
  const client = useAppSelector(state => state.client);
  const {current_brand} = client
  const [isLoading, setIsLoading] = useState(false)
  const [productStatusTiles, setProductStatusTiles] = useState<
    {
      platform: string,
      asins: number,
      listed_locations: number,
      unlisted_locations: number,
      active_locations: number,
      inactive_locations: number,
      listed_percentage: number,
      unlisted_percentage: number,
      active_percentage: number,
      inactive_percentage: number
    }[]
  >([])

  
  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL_NEW}/qcomm/product-status/tiles?client_name=${current_brand?.client_name?.toLowerCase()}`)
      .then((res: any) => {
        console.log(res.data);
        res.data.forEach((tile: {
          platform: string,
          asins: number,
          listed_locations: number,
          unlisted_locations: number,
          active_locations: number,
          inactive_locations: number,
          listed_percentage: number,
          unlisted_percentage: number,
          active_percentage: number,
          inactive_percentage: number
        }) => {
          // const totalListed = tile.listed_locations + tile.unlisted_locations
          // tile.listed_percentage = (tile.listed_locations / totalListed) * 100;
          // tile.unlisted_percentage = (tile.unlisted_locations / totalListed) * 100;

          // const totalActive = tile.active_locations + tile.inactive_locations;
          // tile.active_percentage = (tile.active_locations / totalActive) * 100;
          // tile.inactive_percentage = (tile.inactive_locations / totalActive) * 100;

          const totalLocations = tile.listed_locations + tile.unlisted_locations +
            tile.active_locations + tile.inactive_locations;

          tile.listed_percentage = ((tile.listed_locations) / totalLocations) * 100;
          tile.unlisted_percentage = ((tile.unlisted_locations) / totalLocations) * 100;
          tile.active_percentage = ((tile.active_locations) / totalLocations) * 100;
          tile.inactive_percentage = (tile.inactive_locations / totalLocations) * 100;

        });
        setProductStatusTiles(res.data)
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      })
  }, [current_brand])


  const boxStyle = {
    border: "2px solid #ccc",
    padding: "1rem",
    borderRadius: "8px",
    flex: "1",
    backgroundColor: "#ffffff",
  };
  const borderColor = (color: string) => {
    if (color.toLowerCase() === 'blinkit') return '#FFE141';
    else if (color.toLowerCase() === 'swiggy instamart') return '#FC8019';
    else if (color.toLowerCase() === 'zepto') { return '#950EDB' };
  }
  const BackgroundStripColor = (color: string) => {
    if (color.toLowerCase() === 'blinkit') return '#FFE14140';
    else if (color.toLowerCase() === 'swiggy instamart') return '#FC801940';
    else if (color.toLowerCase() === 'zepto') { return '#950EDB40' };
  }


  return (
    isLoading ? <LoaderSkeletonGraph/>  :
    <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
      {productStatusTiles.map((tile) => (
        <div style={{ ...boxStyle, border: `2px solid ${borderColor(tile.platform)}` }}>
          <div style={{ marginBottom: ".5rem" }}>
            {tile?.platform?.toLowerCase() === 'blinkit' && <BlinkitQcomIcon />}
            {tile?.platform?.toLowerCase() === 'zepto' && <ZeptoQcomIcon />}
            {tile?.platform?.toLowerCase() === 'swiggy instamart' && <InstamartQcomIcon />}
          </div>
          <div style={{ marginBottom: '.5rem' }}>Total Products</div>
          <div style={{ fontSize: "1.2rem", fontWeight: 'bold', marginBottom: "1rem", backgroundColor: BackgroundStripColor(tile.platform), borderRadius: '.25rem', padding: '.1rem 0rem' }}><span style={{ margin: '0rem .6rem', padding: '0px 0px', borderLeft: `4px solid ${borderColor(tile.platform)}`, borderRadius: '2rem' }}></span>{tile.asins}</div>

          <div style={{ border: "1px solid #EFF2F6", borderRadius: ".4rem", padding: ".5rem", justifyContent: "space-between", }}>
            <div style={{}}>
              <div style={{ marginBottom: "1rem", position: "relative", height: "1.7rem", borderRadius: ".25rem", backgroundColor: "#f0f0f0", }}>

                {/* Listed Percentage */}
                <div style={{ width: `${tile.listed_percentage}%`, height: "100%", backgroundColor: "#00B2FF40", borderRadius: ".25rem", position: "absolute", zIndex: 4, }}></div>

                {/* Unlisted Percentage */}
                <div style={{ width: `${tile.unlisted_percentage}%`, height: "100%", backgroundColor: "#DF98F1", borderRadius: ".25rem", position: "absolute", zIndex: 3, left: `${tile.listed_percentage}%`, }} ></div>

                {/* Active Percentage */}
                <div style={{width: `${tile.active_percentage}%`,height: "100%",backgroundColor: "#0DC10040",borderRadius: ".25rem",position: "absolute",zIndex: 2,left: `${tile.listed_percentage + tile.unlisted_percentage}%`, }}></div>

                {/* Inactive Percentage */}
                <div  style={{width: `${tile.inactive_percentage}%`,height: "100%",backgroundColor: "#F3B3B3",borderRadius: ".25rem",position: "absolute",zIndex: 1,left: `${tile.listed_percentage + tile.unlisted_percentage + tile.active_percentage}%`,   }}></div>

              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: "space-between",  }}>


              <div style={{ flex: 1 ,paddingLeft:'1rem'}}>
                <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", fontSize: '.91rem' }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ width: "20px", height: "20px", backgroundColor: "#00B2FF40", borderRadius: "50%", marginRight: ".5rem", }}></span>
                    Listed: {tile.listed_locations}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "20px", height: "20px", background: '#DF98F1', borderRadius: "50%", marginRight: ".5rem", }}></div>
                    Non-listed: {tile.unlisted_locations}
                  </div>
                </div>
              </div>

              <div style={{ flex: 1, paddingLeft:'1rem' }}>
                <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", fontSize: '.91rem' }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ width: "20px", height: "20px", backgroundColor: "#0DC10040", borderRadius: "50%", marginRight: ".5rem", }} ></span>
                    Active: {tile.active_locations}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ width: "20px", height: "20px", backgroundColor: '#F3B3B3', borderRadius: "50%", marginRight: ".5rem", }}></span>
                    In-active: {tile.inactive_locations}
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      ))}
    </div>
  );

};



export default ProductKPIQcom;
