import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";


export default function LoaderSkeletonHarmonization({ height = '9rem', width = '19rem' }) {
  return (
    <div style={{display:'flex' , flexDirection:'column', gap:'1rem', alignItems:'center', marginTop:'1rem' }}>

    <div style={{display:'flex', gap:'1rem' }}>

    <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

  <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

  <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

    </div>

    <div style={{display:'flex', gap:'1rem' }}>

    <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

  <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

  <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

    </div>

    <div style={{display:'flex', gap:'1rem' }}>

    <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

  <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

  <div style={{display:'flex' ,borderRadius:'1rem'}}>
    <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    />
  </div>

    </div>



    </div>
  );
}