import { useEffect, useState } from "react"
import TableOne from "../components/hourlyReport/TableOne"
import TableTwo from "../components/hourlyReport/TableTwo"
import TableZero from "../components/hourlyReport/TableZero"
import { Calendar } from "primereact/calendar"
import { Nullable } from "primereact/ts-helpers"
import { useAppSelector } from "../../../redux/hook"



const HourlyReport = () => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client

    const [clickedKPI, setClickedKPI] = useState<any | null>(null)
    const [clickedHour, setClickedHour] = useState<any | null>(null)
    const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null);

    useEffect(() => {
        // let date2 = new Date();
        // let date1 = new Date(date2.getFullYear(),date2.getMonth(),1)
        let date1 = new Date('2025-01-13')
        let date2 = new Date('2025-01-19')
        setDates([date1, date2])
    }, [])

    const handleDatesChange = (e: any) => {
        setDates(e.value)
    }

    useEffect(() => {
        setClickedKPI(null);
        setClickedHour(null);
    }, [client_id])

    return (
        <section>
            <div>
                <TableZero
                    setClickedHour={setClickedHour}
                    clickedHour={clickedHour}
                    setClickedKPI={setClickedKPI}
                />
            </div>
            {clickedHour &&
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', gap: '0.5rem' }}>
                        <div style={{ color: '#525252', fontWeight: 500 }}>Event :</div>
                        <Calendar value={dates} onChange={handleDatesChange}
                            selectionMode="range" readOnlyInput hideOnRangeSelection
                            minDate={new Date(new Date().getDate() - 60)}
                            maxDate={new Date()}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                    <div>
                        <TableOne
                            setClickedKPI={setClickedKPI}
                            clickedHour={clickedHour}
                            dates={dates}
                        />
                    </div>
                </div>


            }
            {

                clickedKPI &&
                <div>
                    <TableTwo
                        clickedKPI={clickedKPI}
                        dates={dates}
                    />
                </div>
            }


        </section>
    )
}

export default HourlyReport